// SPDX-FileCopyrightText: 2023 Dusan Mijatovic (Netherlands eScience Center)
// SPDX-FileCopyrightText: 2023 Netherlands eScience Center
//
// SPDX-License-Identifier: Apache-2.0

type CounterBoxProps = {
  label: string
  value: Number
}

export default function HgfCounterBox({label,value}:CounterBoxProps) {
  return (
    <div className="text-secondary p-4 h-full flex flex-col">
      <div className="text-6xl">{value.toString()}</div>
      <div className="text-xl grow pb-2">{label}</div>
      <div className="bg-secondary-content w-1/3 h-0.5"></div>
      <div className="bg-secondary-content w-full h-0.5"></div>
    </div>
  )
}
