// SPDX-FileCopyrightText: 2024 Dusan Mijatovic (Netherlands eScience Center)
// SPDX-FileCopyrightText: 2024 Netherlands eScience Center
//
// SPDX-License-Identifier: Apache-2.0

import {useEffect} from 'react'
import HgfCounterBox from './HgfCounterBox'

export type HgfCounterProps = {
  software_cnt: Number,
  project_cnt: Number,
  organisation_cnt: Number,
  contributor_cnt: Number,
  software_mention_cnt: Number,
}

export default function HgfCounterSection(items: HgfCounterProps) {

  const {
    software_cnt,
    project_cnt,
    organisation_cnt,
    contributor_cnt ,
    software_mention_cnt
  } = items

  return (
    <div className='w-full'>
      <section
        id="latest-news"
        className="p-6 md:p-10 w-full max-w-screen-xl mx-auto">

        <h2 className="flex justify-start text-5xl text-secondary">
          The Helmholtz RSD in numbers
        </h2>

        <div className='grid grid-cols-2 md:grid-cols-4 gap-4 pt-10 pb-4'>
          <HgfCounterBox label="Registered software packages" value={software_cnt || 0} />
          <HgfCounterBox label="Contributors to research software" value={contributor_cnt || 0} />
          <HgfCounterBox label="Mentions of research software in science" value={software_mention_cnt || 0} />
          <HgfCounterBox label="International partner organisations" value={organisation_cnt || 0} />
        </div>

      </section>
    </div>
  )

}
